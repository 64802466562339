<template>        
    <LoadedContent class="projects" :loaded="clientsLoaded" :error="clientsError">
        <div  class="projects">
            <h1 >{{ $t('menu.projects') }}</h1>
            <div class="client" v-for="(client,index) in clients" :key="index">
                <h2 v-if="clients.length > 1">{{ client.name }}</h2>
                <div class="projects-group">
                    <RouterLinkLocal class="project-link" v-for="(project,pr_index) in client.projects" :key="pr_index" :link="'url.project/!'+project.item_key+'/url.view'">
                        <BaseButton  type="button" >
                            <div class="project-name">{{ project.name }}</div><br />
                            {{ $t("controls.go_to_project") }}
                        </BaseButton>
                    </RouterLinkLocal>
                </div>
            </div>
        </div>
    </LoadedContent>
</template>

<script>
import useProject from '@admin/composable/ProjectModel';
import LoadedContent from '@common/components/base/LoadedContent';
// import useHeader from "@common/composable/Head";

export default {
    name: "Projects",
    components: {
        LoadedContent
    },
    setup(){
        const { 
            clientsLoaded,
            clientsError,
            clients,
            projects,
        } = useProject(); 
        return {
            clientsLoaded,
            clientsError,
            clients,
            projects
        }
    }
}
</script>

<style scoped>
.project-name{
    font-size: 1.2rem;
    font-weight: 700;
}
.project-link{
    margin: 0.5rem;
    height: 10rem;
}
.project-link>.btn{
    padding: 1.5rem;
}
.client{
    margin: 1rem;
}
</style>